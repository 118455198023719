import { createElement, CSSProperties, FC, PropsWithChildren } from 'react';

import styles from './Flexbox.module.scss';

type FlexboxProps = PropsWithChildren & {
    align?: CSSProperties['alignItems'];
    as?: keyof HTMLElementTagNameMap;
    classes?: string;
    inline?: boolean;
    justify?: CSSProperties['justifyContent'];
    position: CSSProperties['position'];
};

/**
 * Props prevent both row and column from being passed at the same time
 */
type ColumnProps = FlexboxProps & { column: true; row?: never };
type RowProps = FlexboxProps & { row: true; column?: never };

type Props = RowProps | ColumnProps;

/**
 * Flexbox component usable as a generic "container"
 * allowing for easy alignment and justification of children,
 * and reduce repeated flexbox styling.
 */
export const Flexbox: FC<Props> = ({ align, children, inline, justify, position, ...props }) => {
    const directionClass = 'column' in props ? styles['column'] : '';
    const displayClass = inline ? styles['inline-flex'] : '';
    const fillClass = position === 'absolute' ? styles['fill-inset'] : styles['fill-size'];
    const positionClass = position === 'absolute' ? styles['absolute'] : '';
    const flexClasses = [
        styles.Flexbox,
        directionClass,
        displayClass,
        fillClass,
        positionClass,
        props?.classes,
    ].join(' ');

    return createElement(
        props.as || 'div',
        {
            className: flexClasses,
        },
        children,
    );
};
