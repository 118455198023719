/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Match } from '../models/Match';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MatchesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get matches list for team and tournament
     * Get matches list for team and tournament
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchesListForTeamInTournamentOptaMatches({
        teamId,
        tournamentId,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Team whose matches should be returned
         */
        teamId: string,
        /**
         * Tournament that matches should be returned from.
         */
        tournamentId?: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/team/{teamId}',
            path: {
                'teamId': teamId,
            },
            query: {
                'tournamentId': tournamentId,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get matches list for tournament
     * This endpoint works in 2 ways:
     * - request without dates (initial request):
     * return matches for +- 14 days (28 days in total), expect at least 15 matches in past + 15 matches in future, if not found, extend range until found
     * - request with date range (load more clicked - app calculates last shown match, and if user want's to browse to past or future, and sends dates)
     * return matches for range, if not 15 found, extend range
     *
     *
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchesListForTournamentOptaMatches({
        tournamentId,
        startDate,
        reverse,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Tournament that matches should be returned from.
         */
        tournamentId: string,
        /**
         * Start date of matches to be returned. String in format yyyy-MM-dd. If provided, the date range end is + 4 weeksIf not provided, current date will be used to calculate date range. (Date range is +- 2 weeks from current date)
         */
        startDate?: string,
        /**
         * Should past matches from startDate be returned
         */
        reverse?: boolean,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/tournament/{tournamentId}',
            path: {
                'tournamentId': tournamentId,
            },
            query: {
                'startDate': startDate,
                'reverse': reverse,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get future matches list for team
     * This endpoint return matches for + 60 days. If not found, extend range for another 100 days
     * @returns Match Success
     * @throws ApiError
     */
    public getFutureMatchesListForTeamInTournamentOptaMatches({
        teamId,
        startDate,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Team whose future matches should be returned
         */
        teamId: string,
        /**
         * If not provided, current date will be used to calculate date range.
         */
        startDate?: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/team/{teamId}/fixtures',
            path: {
                'teamId': teamId,
            },
            query: {
                'startDate': startDate,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get future matches list for tournament
     * This endpoint return matches for + 60 days. If not found, range is extended
     * @returns Match Success
     * @throws ApiError
     */
    public getFutureMatchesListForTournamentOptaMatches({
        tournamentId,
        startDate,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Tournament that matches should be returned
         */
        tournamentId: string,
        /**
         * If not provided, current date will be used to calculate date range. (Default date range is 60 days)
         */
        startDate?: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/tournament/{tournamentId}/fixtures',
            path: {
                'tournamentId': tournamentId,
            },
            query: {
                'startDate': startDate,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get matches list for date range
     * Get all matches played in specified dates for competitions, that are configured in the admin system. Use timespan not longer than 7 days.
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchesListForSpecifiedDatesOptaMatches({
        startDate,
        endDate,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Start date of matches to be returned. String in format yyyy-MM-dd. String in format yyyy-MM-dd. If not provided, current date will be used
         */
        startDate: string,
        /**
         * End date of matches to be returned. String in format yyyy-MM-dd. String in format yyyy-MM-dd. If not provided, current date will be used
         */
        endDate: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get matches list by Opta IDs
     * Get matches list by Opta IDs
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchesListByIdsOptaMatches({
        ids,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * List of Opta match IDs
         */
        ids?: Array<string>,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/ids',
            query: {
                'ids': ids,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get upcoming matches with odds for teams
     * Get upcoming matches with odds based on provided opta ids
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchesWithOddsOptaMatches({
        optaIDs,
        limit = 2,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Filter tags by multiple team optaIds.
         */
        optaIDs?: Array<string>,
        /**
         * amount of matches
         */
        limit?: number,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/with-odds',
            query: {
                'optaIDs': optaIDs,
                'limit': limit,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
}
