
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect, useMemo } from 'react';
import { sendGAEvent } from '@next/third-parties/google';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import { FontFaces } from '@common/atoms/FontFaces';
import { SupertokensWrapper } from '@common/atoms/SupertokensWrapper';
import { AdPlacement, PlatformID } from '@common/clients/api';
import { ContextData, isContextData } from '@common/defaults';
import { logger } from '@common/logger';
import { AdsByPlacement } from '@common/types';
import { NextContext } from '@common/useContextData';
import { getHostByHostname, getPlatformIdByHostname, Host } from '@common/utils/HostUtil';
import { BettingMeta } from '@web/atoms/BettingMeta';
import { FaviconLinks } from '@web/atoms/FaviconLinks';
import { AccountIntroduction } from '@web/molecules/AccountIntroduction/ClientSideAccountIntroduction';
import { Ad } from '@web/molecules/Ad';
import { OrganizationStructuredData } from '@web/molecules/StructuredData';
import { TrackingHandler } from '@web/molecules/TrackingHandler';
import { Footer } from '@web/organisms/Footer';
import { Header } from '@web/organisms/Header';
import { Route } from '@web/routing';
import '@common/logger/browser';
import Error, { ErrorBoundary } from './_error';
import '../styles/index.scss';
function MyApp({ Component, pageProps, router }: AppProps) {
    const isServerSide = typeof window === 'undefined' || window.navigator.appVersion === 'undefined';
    const props: any = { ...pageProps };
    const contextData: ContextData | undefined = isContextData(props?.contextData)
        ? props.contextData
        : undefined;
    let origin = contextData ? contextData.hostname : '';
    logger.registerAdditionalContext({
        locale: router.locale,
        pathname: router.asPath,
        route: router.route
    });
    // FIXME: avoid router to handle non-shallow routes
    const nextRouter = useRouter();
    useEffect(() => {
        const handleRouteChange = (url: string, options: any) => {
            if (options?.shallow === false) {
                window.location.pathname = url;
            }
        };
        nextRouter.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [nextRouter.events, router.events]);
    if (!isServerSide && (!origin || origin.length)) {
        origin = window?.location?.hostname ?? '';
    }
    const platformID = getPlatformIdByHostname(origin);
    const theme = useMemo(() => {
        if (router.route === '/liveness')
            return 'vp';
        else if (getHostByHostname(origin) === Host.VP_BE)
            return 'vpbe';
        else if (platformID)
            return platformID;
        else if (process.env.NEXT_PUBLIC_THEME)
            return process.env.NEXT_PUBLIC_THEME;
        else if (contextData)
            return contextData.platform.id;
        else if (process.env.APP_ENV === 'development' || process.env.CI === 'true')
            return 'vp';
        else {
            logger.warn(`Theme was not found for origin '${origin}'. Defaulting to 'vp'.`);
            return 'vp';
        }
    }, [origin, platformID, contextData, router.route]);
    // When ads are not fetched by the getServerSideProps, make sure we still fetch them client-side
    const ads = useMemo<AdsByPlacement>(() => {
        if (contextData && contextData.ads)
            return contextData.ads;
        if (props.ads)
            return props.ads;
        if (props.errorCode || !contextData || isServerSide)
            return {};
        return import('@web/handlers/fetchAds').then((mod) => mod.fetchAds(contextData, nextRouter.query, true));
    }, [platformID, isServerSide]); // eslint-disable-line react-hooks/exhaustive-deps
    if (contextData)
        contextData.ads = ads;
    // Avoid next.voetbalprimeur.nl and next.staging.voetbalprimeur.nl to be crawled
    const isCrawlable = contextData && !/next\./.test(contextData.hostname) && /www\./.test(contextData.context.url);
    const isNextCall = Boolean(contextData && /next\./.test(contextData.hostname));
    return contextData ? (<ThemeProvider forcedTheme={theme}>
            <FontFaces platform={platformID || contextData.platform.id || PlatformID.VP}/>
            <NextContext.Provider value={contextData}>
                <SupertokensWrapper>
                    {props.errorCode ? (<Error {...props}/>) : (<ErrorBoundary>
                            <Head>
                                {isCrawlable ? (<meta name="robots" content="max-image-preview:large"/>) : (<meta name="robots" content="noindex, nofollow"/>)}
                            </Head>
                            <FaviconLinks />
                            <BettingMeta />
                            <TrackingHandler />
                            {contextData?.context ? <OrganizationStructuredData /> : null}

                            {isNextCall && !contextData.isDarkWebCall ? (<>
                                    <Header />
                                    <main>
                                        <div className="full-width">
                                            {contextData.adsEnabled &&
                    contextData.route !== Route.Homepage ? (<Ad isOptional placement={AdPlacement.NON_HOME_BEFORE_CONTENT}/>) : null}
                                            <Component {...props}/>
                                        </div>
                                    </main>
                                    <Footer />
                                </>) : (<>
                                    {contextData.adsEnabled && contextData.route !== Route.Homepage ? (<Ad isOptional placement={AdPlacement.NON_HOME_BEFORE_CONTENT}/>) : null}
                                    <Component {...props}/>
                                </>)}
                        </ErrorBoundary>)}
                </SupertokensWrapper>
                <AccountIntroduction />
            </NextContext.Provider>
        </ThemeProvider>) : (<ThemeProvider forcedTheme={theme}>
            <FontFaces platform={platformID || PlatformID.VP}/>
            <Head>
                <meta name="robots" content="noindex, nofollow"/>
            </Head>
            {props.errorCode ? (<Error {...props}/>) : (<ErrorBoundary>
                    <Component {...props}/>
                </ErrorBoundary>)}
        </ThemeProvider>);
}
export function reportWebVitals(metric: NextWebVitalsMetric) {
    logger.debug(metric);
    sendGAEvent('event', metric.name, {
        event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
        event_label: metric.id,
        non_interaction: true
    });
}
const __Next_Translate__Page__1916d254050__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1916d254050__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  